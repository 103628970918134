import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCVsThunk,
  fetchRaLeadByIdThunk,
} from "../../store/slices/ra/ra.slice";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Stack,
  Avatar,
  Paper,
  Button,
  TextField,
  IconButton,
  Input,
  Container,
} from "@mui/material";
import Page from "../../components/Page";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { makeStyles } from "@mui/styles";
import Modal from "../../components/Modal/Modal";
import UploadCvs from "../FormData/UploadCvs";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import CVTable from "./CVTable";
import AttachCV from "../FormData/AttachCV";
import ShowAssessments from "./ShowAssessments";

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 12,
    minWidth: 256,
    textAlign: "center",
  },
  portfolioHeader: {
    "& span": {
      lineHeight: 1.3,
      height: "40px",
    },
  },
  avatar: {
    width: 60,
    height: 60,
    margin: "auto",
  },
  heading: {
    fontSize: 18,
    fontWeight: "bold",
    letterSpacing: "0.5px",
    marginTop: 8,
    marginBottom: 0,
  },
  subheader: {
    fontSize: 14,
    color: "gray",
    marginBottom: "0.875em",
  },
  statLabel: {
    fontSize: 12,
    color: "gray",
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 4,
    letterSpacing: "1px",
  },
  borderedGridStyles: {
    borderColor: "rgba(0, 0, 0, 0.08)",
    textAlign: "center",
  },
  "@media (max-width:1000px)": {
    cardInMobile: {
      paddingLeft: "40px !important",
      paddingRight: "20px",
    },
  },
  "@media (max-width:550px)": {
    cardInMobile: {
      margin: 15,
      padding: 0,
      paddingLeft: "30px !important",
    },
    cardParent: {
      display: "-webkit-inline-box",
    },
    centerDiv: {
      display: "flex !important",
      justifyContent: "center !important",
    },
  },
}));

function RaCvs() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  // const lead = useSelector(({ ra }) => ra.lead);
  const cvs = useSelector(({ ra }) => ra.cvs);
  // useEffect(() => {
  //   dispatch(fetchRaLeadByIdThunk(params.leadId));
  // }, []);

  const [open, setOpen] = useState(false);

  function showCvModal() {
    setOpen(true);
  }

  function hideCvModal() {
    setOpen(false);
  }
  const [openAttachCvModal, setOpenAttachCvModal] = useState(false);
  const [openAssessmentModal, setOpenAssessmentModal] = useState({
    open: false,
    data: {},
  });
  console.log(openAssessmentModal);

  function showAttachCvModal() {
    setOpenAttachCvModal(true);
  }

  function hideAttachCvModal() {
    setOpenAttachCvModal(false);
  }
  function hideAssessmentModal() {
    setOpenAssessmentModal((prevState) => ({
      ...prevState,
      open: false,
    }));
    setTimeout(() => {
      setOpenAssessmentModal((prevState) => ({
        ...prevState,
        data: {},
      }));
    }, 500);
  }

  useEffect(() => {
    dispatch(fetchAllCVsThunk(params.leadId));
  }, [open, openAttachCvModal]);

  return (
    <>
      {/* <Page title="Portfolio | EMB"> */}
      <Card
        sx={{
          padding: "2rem",
        }}
      >
        {/* <Container> */}
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container justifyContent="center" alignItems="center" py={3}>
              <Grid md={6}>
                <Typography variant="h5" ml={1}>
                  Upload CV
                </Typography>
              </Grid>
              <Grid md={6}>
                <Stack
                  mr={3}
                  direction="row"
                  spacing={2}
                  sx={{
                    marginLeft: { sm: "23px", xs: "23px" },
                    float: "right",
                  }}
                >
                  <Button
                    variant="contained"
                    to="/dashboard/user/createUser"
                    startIcon={<Icon icon={plusFill} />}
                    onClick={showCvModal}
                  >
                    Add New CV
                  </Button>
                  <Button
                    variant="outlined"
                    to="/dashboard/user/createUser"
                    startIcon={<Icon icon={plusFill} />}
                    onClick={showAttachCvModal}
                  >
                    Choose from bench
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* ------------------ UploadedCvsdata ----------÷ */}
        <Box py={2}>
          <CVTable cvs={cvs} setOpenAssessmentModal={setOpenAssessmentModal} id={params.leadId}/>
        </Box>
        {/* -------------------------- ENDS HERE ---------------- */}
        {/* </Container> */}
      </Card>
      {/* </Page> */}
      <Modal
        isOpen={open}
        closeModal={() => {
          hideCvModal();
        }}
        content={
          <UploadCvs
            sx={{ marginTop: "20px" }}
            hidePortfolioModalCheck={hideCvModal}
            raId={params.leadId}
          />
        }
        contentStyle={{ minWidth: "400px" }}
        ac
      />
      <Modal
        isOpen={openAttachCvModal}
        closeModal={() => {
          hideAttachCvModal();
        }}
        content={
          <AttachCV
            sx={{ marginTop: "20px" }}
            hidePortfolioModalCheck={hideAttachCvModal}
            raId={params.leadId}
          />
        }
        contentStyle={{ minWidth: "300px" }}
        maxWidth={"xl"}
      />
      <Modal
        isOpen={openAssessmentModal?.open}
        closeModal={hideAssessmentModal}
        content={
          <ShowAssessments
            data={openAssessmentModal?.data}
            sx={{ marginTop: "20px" }}
            hidePortfolioModalCheck={hideAssessmentModal}
            // raId={params.leadId}
          />
        }
        contentStyle={{ minWidth: "100px" }}
        maxWidth={"sm"}
      />
    </>
  );
}

export default RaCvs;
