import { Box, Button, Container, Grid } from "@mui/material";
import React from "react";
import Element from "../../components/Form/Element";
import useForm from "../../hooks/useForm";
import { inputType } from "../../utils/enum";
import { partnersOptions } from "../../utils/options.utils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { sassChannelDetailsInfoValidation } from "../../utils/validations/vendor.validation";
import MobileInput from "../../components/Form/MobileInput";
import { useDispatch } from "react-redux";
import { createSassChannelProfileThunk } from "../../store/slices/vendor/vendor.slice";
import moment from "moment-timezone";

const SassChannelProfile = ({ singleSassChannelData, closeModal }) => {
  const { name, type, id, number, valid_to, file, code } =
    singleSassChannelData ?? {};
  const dispatch = useDispatch();

  // submit form input
  const onSubmit = (data) => {
    var formData = new FormData();
    formData.append("name", data?.sass_name);
    formData.append("type", data?.sass_typeSelect);
    formData.append("number", data?.mobile?.mobile_number);
    formData.append("code", data?.mobile?.country_code);
    formData.append(
      "valid_to",
      moment(data?.sass_date_validTo)?.format("YYYY-MM-DD")
    );
    if (data?.file?.name) {
      formData.append("file", data?.file);
    }

    let pastData = new FormData();
    pastData.append("name", name);
    pastData.append("type", type);
    pastData.append("number", number);
    pastData.append("code", code);
    pastData.append("valid_to", valid_to);
    pastData.append("file", file);
    // {
    //   sass_name:name ,
    //     mobile: {
    //       country_code:code,
    //       mobile_number:number,
    //     },
    //     sass_date_validTo:valid_to ? new Date(valid_to) : new Date(),
    //     sass_typeSelect:type,
    //     file:file
    // }

    // dispatch(createPOCThunk({ data: serverData, page: page }))
    if (!singleSassChannelData) {
      dispatch(createSassChannelProfileThunk({ data: formData }))
        // .unwrap()
        .then(closeModal);
    } else {
      dispatch(
        createSassChannelProfileThunk({
          data: formData,
          pastData: pastData,
          id,
        })
      )
        // .unwrap()
        .then(closeModal);
    }
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useForm({
      initialValues: {
        sass_name: name,
        mobile: {
          country_code: code,
          mobile_number: number,
        },
        sass_date_validTo: valid_to ? new Date(valid_to) : new Date(),
        sass_typeSelect: type,
        file: file,
      },
      onSubmit: onSubmit,
      validationSchema: sassChannelDetailsInfoValidation,
    });

  return (
    <Container>
      <Box>
        <Grid container spacing={2} mt={5}>
          <Grid item md={12}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1} p={3}>
                <Grid item md={12} pb={3}>
                  <Element
                    label={
                      <>
                        Name
                        <span style={{ color: "red" }}>*</span>
                      </>
                    }
                    inputProps={{
                      placeholder: "Enter Brand Name",
                      type: "text",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: "sass_name",
                    }}
                    eletype={inputType.input}
                    value={values.sass_name}
                    errorText={touched.sass_name && errors.sass_name}
                  />
                </Grid>
                <Grid item md={12} p={3}>
                  <Element
                    label={
                      <>
                        Type
                        <span style={{ color: "red" }}>*</span>
                      </>
                    }
                    inputProps={{
                      name: "sass_typeSelect",
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={values.sass_typeSelect}
                    options={partnersOptions}
                    errorText={
                      touched.sass_typeSelect && errors.sass_typeSelect
                    }
                    eletype={inputType.select}
                    icons={
                      <ArrowDropDownIcon
                        sx={{ position: "absolute", right: 20, bottom: 24 }}
                      />
                    }
                  />
                </Grid>
                <Grid item md={12} p={3}>
                  <MobileInput
                    label={
                      <>
                        Enter Mobile
                        <span style={{ color: "red" }}>*</span>
                      </>
                    }
                    name="mobile"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mobile}
                    errorText={touched.mobile && errors.mobile}
                  />
                </Grid>
                <Grid item md={12} p={3}>
                  <Element
                    label={
                      <>
                        Valid To
                        <span style={{ color: "red" }}>*</span>
                      </>
                    }
                    inputProps={{
                      placeholder: "Please Enter Date",
                      type: "text",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: "sass_date_validTo",
                    }}
                    eletype={inputType.date}
                    value={values.sass_date_validTo}
                    minDateShow={new Date()}
                    errorText={
                      touched.sass_date_validTo && errors.sass_date_validTo
                    }
                  />
                </Grid>

                <Grid item md={12} p={3}>
                  <Element
                    label={"Certificate File"}
                    // label={
                    //   <>
                    //     Certificate File
                    //     <span style={{ color: "red" }}>*</span>
                    //   </>
                    // }
                    inputProps={{
                      name: "file",
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={values.file}
                    eletype={inputType.file}
                    icons={
                      <ArrowDropDownIcon
                        sx={{ position: "absolute", right: 20, bottom: 24 }}
                      />
                    }
                    fileUploadWithoutUrl={true}
                  />
                </Grid>
                <Grid item md={12} p={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ float: "right" }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SassChannelProfile;
