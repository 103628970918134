import {
  addVendorCV,
  attachVendorCVs,
  fetchAllCVs,
  fetchAllRA,
  fetchRAById,
  fetchRAByLineItems,
  notInterested,
  uploadAssignment,
  submitAssessment,
} from "../../../utils/apis.utils";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";

export const fetchAllRALeadsHandler = async (__, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(fetchAllRA + "?query=");
  return response;
};

export const fetchAllRALeadsPaginationHandler = async (page = 1, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(fetchAllRA + "?page=" + page);
  return response;
};

export const fetchRaLeadByIdHandler = async (id, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(fetchRAById + "/" + id);
  return response.data;
};

export const fetchRaLeadLineItemsHandler = async (id, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(fetchRAByLineItems + "/" + id);
  return response.data;
};

export const fetchAllCVsHandler = async (id, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(fetchAllCVs + "/" + id);
  return response.data;
};

export const addCVsHandler = async ({ raId, formData }, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  try {
    const response = await vendor.post(addVendorCV + "/" + raId, formData);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};
export const attachCVsHandler = async ({ raId, data }, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  const payload = {
    bench_strength_ids: data,
  };
  try {
    const response = await vendor.post(attachVendorCVs + "/" + raId, payload);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const putNotInterestedHandler = async (leadid, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  try {
    const response = await vendor.put(notInterested + "/" + leadid);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const uploadAssignmentsForRA = async ({ raId, formData }, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  try {
    let apiEndpoint = `${uploadAssignment}${raId}`
    const response = await vendor.post(apiEndpoint, formData);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const handleSubmitAssessment = async (dataToSend, thunkApi) => {
  const { vendor } = thunkApi.extra.apiService;
  try {
    const response = await vendor.put(submitAssessment, { data: dataToSend });
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};
