import * as Yup from "yup";

export const addressInfovalidation = Yup.object({
  address_type: Yup.string().required("Address type is required"),
  address_line1: Yup.string().required("Address1 is required"),
  // address_line2: Yup.string().required("Address2 is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  zip_code: Yup.string().required("Pincode is required"),
});

export const bankDetailsValidation = Yup.object({
  bank_name: Yup.string().required("Bank name is required"),
  account_number: Yup.string().required("Account number is required"),
  ifsc_code: Yup.string().required("IFSC code is required"),
  bank_branch: Yup.string().required("Branch name is required"),
  cancelled_cheque_file: Yup.object({
    url: Yup.string().required("Cancelled Cheque is required"),
  }),
});

export const turnoverValidations = Yup.object({
  year: Yup.string().required("Year is required"),
  projectBooked: Yup.string().required("Project Booked is Required"),
  projectBookedAmount: Yup.string().required(
    "Project Booked Amount is Required"
  ),
  projectDelivered: Yup.string().required("Project Delivered is Required"),
  projectDeliveredAmount: Yup.string().required(
    "Project Delivered Amount is Required"
  ),
});

export const servicesValidation = Yup.object({
  service_name: Yup.string().required("Service name is required"),
  minimum_price: Yup.string().required("Minimum price is required"),
  maximum_price: Yup.string().required("Maximum price is required"),
  notes: Yup.string().required("Notes is required"),
});

export const passwordvalidation = Yup.object().shape({
  old_password: Yup.string().required("Old password is required"),
  new_password: Yup.string().required("New password is required"),
  confirmpass: Yup.string()
    .required("Confirm password is required")
    .when("new_password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("new_password")],
        "Both password need to be the same"
      ),
    }),
});

export const portfolioValidation = Yup.object({
  client_name: Yup.string().trim().required("Client Name is required"),
  // // website_link: Yup.string().url("Please Enter a Valid url"),
  // // app_link: Yup.string().url("Please Enter a Valid url"),
  // // client_budget: Yup.string().required("Project budget is required"),
  // // description: Yup.string().required("Description is required"),
  // service: Yup.object({
  service: Yup.string().required("Service name is Required"),
  industry: Yup.string().required("Industry name is Required"),
  sub_industry: Yup.string().required("Sub Industry name is Required"),
  delivery_class: Yup.string().required("Delivery Class is Required"),
  // }),
  // product_code: Yup.object().shape({
  //   id: Yup.string().required("Product code is Required"),
  //   // code: Yup.string().required("Product Code is Required"),
  // }),
  links_details: Yup.array().of(
    Yup.object().shape({
      frontend: Yup.string().required("Frontend is required"),
      backend: Yup.string().required("Backend is required"),
      cloud: Yup.string().required("Cloud is required"),
      database: Yup.string().required("Database  is required"),
    })
  ),
});

export const verificationValidation = Yup.object({
  pan_number: Yup.string().required("Business PAN is required").nullable(),
  pan_file: Yup.object({
    url: Yup.string().required("Business PAN file is required"),
  }),
  nda_file: Yup.object({
    url: Yup.string().required("NDA file is required"),
  }),
  gst_number: Yup.string().required("GST Number is required").nullable(),
  gst_file: Yup.object({
    url: Yup.string().required("GST file is required"),
  }),
  aadhaar_number: Yup.string()
    .required("Aadhaar Number is required")
    .matches(
      /^[0-9]{12}$/,
      "Aadhaar Number must be exactly 12 digits and contain only numbers"
    )
    .nullable(),

  aadhaar_file: Yup.object({
    url: Yup.string().required("Aadhaar file is required"),
  }),
  // udyog_number: Yup.string().required("Udyog Number is required"),
  // msme_reg_number: Yup.string().required("MSME is required"),
  // msme_reg_file: Yup.object({
  //   url: Yup.string().required("MSME file is required"),
  // }),
});
export const verificationValidationWithMSME = Yup.object({
  pan_number: Yup.string().required("Business PAN is required").nullable(),
  pan_file: Yup.object({
    url: Yup.string().required("Business PAN file is required"),
  }),
  nda_file: Yup.object({
    url: Yup.string().required("NDA file is required"),
  }),
  gst_number: Yup.string().required("GST Number is required").nullable(),
  gst_file: Yup.object({
    url: Yup.string().required("GST file is required"),
  }),
  aadhaar_number: Yup.string()
    .required("Aadhaar Number is required")
    .matches(
      /^[0-9]{12}$/,
      "Aadhaar Number must be exactly 12 digits and contain only numbers"
    )
    .nullable(),
  aadhaar_file: Yup.object({
    url: Yup.string().required("Aadhaar file is required"),
  }),
  msme_type: Yup.string().required("MSME Type is required"),
  msme_reg_number: Yup.string()
    .required("MSME Registration Number is required")
    .nullable(),
  msme_reg_file: Yup.object({
    url: Yup.string().required("MSME Registration file is required"),
  }),
});

export const basicInfoValidations = Yup.object({
  brand_name: Yup.string().trim().required("BrandName is required"),
  // registered_business_name: Yup.string().required("Business Name is required"),
  company_type: Yup.string().required("Company Type is required"),
  // mobile: Yup.object({
  //   mobile_number: Yup.string()
  //     .required("Mobile is required")
  //     .test(
  //       "len",
  //       "Please Enter a Valid Mobile Number",
  //       (val) => val.length === 10
  //     ),
  // }),
  // mobile_number: Yup.string().trim().required("Mobile Number is required"),
  year_of_establishment: Yup.string().required(
    "Year of Establishment is required"
  ),
  // turnover: Yup.string().required("TurnOver is required"),
  number_of_employees: Yup.string().required("Number of Employees is required"),
  number_of_clients: Yup.string().required("Number of Clients is required"),
  website: Yup.string().trim().required("Website is required"),
  activeProjectNumber: Yup.number()
    .typeError("Active Project Number must be a number")
    .positive("Active Project Number must be a positive number")
    .integer("Active Project Number must be an integer")
    .required("Active Project Number is required"),
  cmmiLevelSelect: Yup.string().required("CMMI Level is required"),
  // logo: Yup.object({
  //   url: Yup.string().required("Logo is required"),
  // }),
  description: Yup.string().required("Description is required"),
});

export const languageValidations = Yup.object({
  language: Yup.string().required("Language is required"),
});

export const skillsValidations = Yup.object({
  skills: Yup.string().required("Skills is required"),
});

export const notesValidations = Yup.object({
  note: Yup.string().required("Notes is required"),
});

//sass channel partner validation
export const sassChannelDetailsInfoValidation = Yup.object({
  sass_name: Yup.string()
    .required("Name is Required")
    .min(3, "Please Enter Minimum 3 Charcter")
    .test(
      "no-spaces",
      "Name cannot be only spaces",
      (value) => !/^\s+$/.test(value)
    ),
  sass_typeSelect: Yup.string().required("Please Atleast Select one"),
  sass_date_validTo: Yup.string().required("Date is Required").nullable(),
  mobile: Yup.object({
    mobile_number: Yup.string()
      .required("Mobile is Required")
      .max(10, "Please Enter a Valid Mobile Number")
      .min(10, "Please Enter a Valid Mobile Number")
      .test(
        "no-zero-only",
        "Mobile number cannot contain only 0",
        (value) => !/^[0]*$/.test(value)
      ),
  }),
});
//certification validation
export const certificationValidation = Yup.object({
  type: Yup.string().required("Certification Type is Required"),
  valid_to: Yup.string().required("Please Select Date").nullable(),
  file: Yup.mixed().required("File is Required"),
  number: Yup.object({
    mobile_number: Yup.string()
      .required("Mobile is Required")
      .max(10, "Please Enter a Valid Mobile Number")
      .min(10, "Please Enter a Valid Mobile Number"),
  }),
  name: Yup.string().required("Name is Required"),
});
//Testimonial validation
export const testimonialsValidation = Yup.object({
  spoc_name: Yup.string()
    .required("SPOC Name is Required")
    .min(3, "Please Enter Minimum 3 Character")
    // .test(
    //   "no-spaces",
    //   "SPOC Name cannot be only spaces",
    //   (value) => !/^\s+$/.test(value)
    // ),
    .matches(/^[a-zA-Z ]+$/, "Only alphabetic characters are allowed"),
  email: Yup.string()
    .required("Email is Required")
    .email("Enter the valid email"),
  company_name: Yup.string()
    .required("Company Name is Required")
    .test(
      "no-spaces",
      "Comapany Name cannot be only spaces",
      (value) => !/^\s+$/.test(value)
    ),
  contact: Yup.object({
    mobile_number: Yup.string()
      .required("Mobile is Required")
      .max(10, "Please Enter a Valid Mobile Number")
      .min(10, "Please Enter a Valid Mobile Number")
      .test(
        "no-zero-only",
        "Mobile number cannot contain only 0",
        (value) => !/^[0]*$/.test(value)
      ),
  }),
  service_delivered: Yup.string()
    .required("Service Delivered is Required")
    .test(
      "no-spaces",
      "Service Delivered cannot be only spaces",
      (value) => !/^\s+$/.test(value)
    ),
  // product_code: Yup.string().required("Product Code is Required"),
});

//poc validation
export const escalationLevelValidations = Yup.object({
  name: Yup.string()
    .trim()
    .required("Name is required")
    .min(3, "Please Enter Minimum 3 Charcter")
    .matches(/^[a-zA-Z ]+$/, "Only alphabetic characters are allowed"),
  // last_name: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .trim()
    .email("Invalid Email format")
    .required("Email ID is required"),
  designation: Yup.string().trim().required("Designation is required"),
  // age_range: Yup.string().required("Age Range  is required"),
  mobile: Yup.object({
    mobile_number: Yup.string().required("Contact Number is Required"),
  }),
  // escalation_level: Yup.string().required("Escalation Level is required"),
});

//founder profile validation
export const founderProfileInfoValidation = Yup.object({
  name: Yup.string()
    .required("Name is Required")
    .min(3, "Please Enter Minimum 3 Charcter")
    .matches(/^[a-zA-Z ]+$/, "Only alphabetic characters are allowed"),
  type: Yup.string().required("Please Atleast Select one"),
  email: Yup.string()
    .required("Email ID is  Required")
    .email("Enter the valid email"),
});

//bench strength validation
export const BenchStrengthValidation = Yup.object({
  candidate_name: Yup.string().trim().required("Candidate Name is required"),
  experience: Yup.string().required("Experince is Required"),
  team_model: Yup.string().required("Team Name is Required"),
  cost_per_month: Yup.string().required("Cost Per Month is Required"),
  cv: Yup.object({
    url: Yup.string().required("CV file is required"),
  }).nullable(),
});

//past work tabs validation
export const pastWorkValidation = Yup.object().shape({
  // service: Yup.string().required("Service Field  is Required"),
  //   industry: Yup.string().required("Industry Field  is Required"),
  //   sub_industry: Yup.string().required("Sub Industry Field   is Required"),
  //   delivery_class: Yup.string().required("Delivery Class  is Required"),
  //   service:Yup.array().of(
  //     Yup.object().shape({
  //         name: Yup.string().required("Service Field  is Required"),
  //         id: Yup.string().required("Service Field  is Required"),
  //     })
  // )
  //   // service: Yup.object({
  //   //   name: Yup.string().required("Service Field  is Required")
  //   // }),
});

//social media tabs  valiadtion
export const socialMediaValidation = Yup.object().shape({
  // Define validation rules for each field
  socialMedia: Yup.array().of(
    Yup.object().shape({
      platform: Yup.string().required("Platform is required"),
      rating: Yup.string().required("Rating is required"),
      link: Yup.string().required("Link is required"),
    })
  ),
});
