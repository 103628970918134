import React, { useRef, useState } from "react";

import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  Button,
} from "@mui/material";
import Scrollbar from "../../components/common/Scrollbar";
import ListHead from "../../components/common/ListHead";
import { capitalCase } from "../../components/common/change-case";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Close } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  fetchAllCVsThunk,
  submitAssessmentThunk,
  uploadAssignmentsThunk,
} from "../../store/slices/ra/ra.slice";
import { extractShortFilename } from "../../utils/extractFilenameFromUrl";

const TABLE_HEAD = [
  // { id: "id", label: "Id", alignRight: false },
  { id: "applicant_name", label: "Name", alignRight: false },
  { id: "skill", label: "Skills", alignRight: false },
  { id: "vendor_budget", label: "Vendor Budget", alignRight: false },
  { id: "year_of_exp", label: "Year Of Exp", alignRight: false },
  // { id: "cv_filename", label: "CV", alignRight: false },
  { id: "emb_applicant_status", label: "Status", alignRight: false },
  {
    id: "file_upload",
    label: "Action",
    alignRight: false,
  },
];

function CVTable({ cvs, setOpenAssessmentModal, id }) {
  const dispatch = useDispatch();
  function handleRowClick() {}

  const [rowFiles, setRowFiles] = useState({});
  console.log(rowFiles);
  const handleFileChange = (event, rowId) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.size > 2 * 1024 * 1024) {
      alert("The selected file is too large (max 2MB).");
    } else {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("upload_type", "assessment_submit");

      dispatch(uploadAssignmentsThunk({ raId: rowId, formData })).then(
        (res) => {
          // console.log(res, "res");
          if (res?.type === "uploadAssignmentsThunk/fulfilled") {
            setRowFiles((prevFiles) => {
              const updatedFiles = { ...prevFiles };
              if (!updatedFiles[rowId]) updatedFiles[rowId] = [];
              updatedFiles[rowId].push(res?.payload);
              return updatedFiles;
            });
            // setRowFiles((prevFiles) => ({
            //   ...prevFiles,
            //   [rowId]: [...(prevFiles[rowId] || []), res?.payload?.url],
            // }));
          }
        }
      );
    }

    event.target.value = "";
  };

  const handleRemove = (rowId, index) => {
    setRowFiles((prevFiles) => ({
      ...prevFiles,
      [rowId]: prevFiles[rowId].filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = (row) => {
    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to submit these files?"
    );

    if (isConfirmed) {
      const requestData = {
        requirement_id: row.requirement_id,
        applicant_id: row.id.toString(),
        assignment_data: rowFiles[row.id] || [],
      };

      dispatch(submitAssessmentThunk(requestData)).then((res) =>
        // console.log(res, "res")
        {
          if (res?.type === "submitAssessmentThunk/fulfilled") {
            dispatch(fetchAllCVsThunk(id));
            setTimeout(() => {
              setRowFiles((prevFiles) => ({
                ...prevFiles,
                [row?.id]: [],
              }));
            }, 1000);
          }
        }
      );
    } else {
      console.log("Submission canceled.");
    }
  };

  return (
    <>
      <Card>
        <Scrollbar>
          {cvs.length > 0 ? (
            <TableContainer
              sx={{
                width: "100%",
                overflowX: "auto", // Enable horizontal scrolling
              }}
            >
              <Table>
                <ListHead
                  rowCount={cvs.length}
                  styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                  headLabel={TABLE_HEAD}
                />
                <TableBody>
                  {cvs.map((row) => {
                    const {
                      applicant_name,
                      cv_filename,
                      cv_url,
                      emb_applicant_status,
                      skill,
                      skill_name,
                      vendor_budget,
                      year_of_exp,
                      id,
                      assignments,
                      submitted_assignments,
                      client_applicant_status,
                    } = row;

                    let status = "";

                    if (emb_applicant_status.includes("cv")) {
                      let new_arr = [];
                      let statusArr = emb_applicant_status.split("_");
                      for (let i = 0; i < statusArr.length; i++) {
                        if (statusArr[i].includes("cv")) new_arr.push("CV");
                        else new_arr.push(capitalCase(statusArr[i]));
                      }
                      status = new_arr.join(" ");
                    } else {
                      status = capitalCase(
                        emb_applicant_status.split("_").join(" ")
                      );
                    }

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        sx={{ cursor: "pointer", zIndex: "" }}
                        onClick={() => handleRowClick(id)}
                      >
                        {/* <TableCell align="left">{id}</TableCell> */}

                        <TableCell align="left">
                          {capitalCase(applicant_name)}
                        </TableCell>

                        <TableCell align="left">
                          {capitalCase(skill_name)}
                        </TableCell>

                        <TableCell align="left">
                          {capitalCase(vendor_budget)}
                        </TableCell>

                        <TableCell align="left">
                          {capitalCase(year_of_exp)}
                        </TableCell>

                        {/* <TableCell align="left">
                          <a href={cv_url} target="_blank">
                            {cv_filename}
                          </a>
                        </TableCell> */}

                        <TableCell align="left">{status}</TableCell>
                        <TableCell
                          align="left"
                          // style={{
                          //   position: "sticky",
                          //   right: 0,
                          //   backgroundColor: "white", // Keeps background white
                          //   zIndex: 1, // Keeps action column above the table
                          //   width: "auto",
                          // }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: 30,
                              // justifyContent:'space-around',
                              alignItems: "center",
                              // gap: "1px", // Adjust spacing between the buttons
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <>
                                {" "}
                                <Button
                                  disabled={client_applicant_status !== 'assessment_completed' }
                                  style={{
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    gap: 10,
                                  }}
                                  variant="outlined"
                                  size="small"
                                  onClick={() =>
                                    window.document
                                      .getElementById(`file-upload-input-${id}`)
                                      .click()
                                  }
                                >
                                  <input
                                    id={`file-upload-input-${id}`}
                                    type="file"
                                    hidden
                                    accept="image/*,application/pdf"
                                    onChange={(event) =>
                                      handleFileChange(event, id)
                                    }
                                  />
                                  <CloudUploadOutlinedIcon
                                    style={{ transition: "color 0.3s" }}
                                    fontSize="small"
                                  />
                                  <span style={{ color: "#666" }}>
                                    Assessment
                                  </span>
                                </Button>
                                <Button
                                  disabled={!(rowFiles[id]?.length > 0)}
                                  style={{
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                  }}
                                  size="small"
                                  variant="contained"
                                  onClick={() => handleSubmit(row)}
                                >
                                  Submit Files
                                </Button>
                              </>
                            </div>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() =>
                                setOpenAssessmentModal({
                                  open: true,
                                  data: {
                                    assignments: assignments,
                                    submitted_assignments:
                                      submitted_assignments,
                                    cv_url: cv_url,
                                    cv_filename: cv_filename,
                                  },
                                })
                              }
                            >
                              view files
                            </Button>
                          </div>

                          {/* Show uploaded files only for this row */}
                          {/* Show uploaded files only for this row */}
                          {rowFiles[id]?.length > 0 && (
                            <div
                              style={{
                                marginTop: "8px",
                                fontSize: "12px",
                                color: "#333",
                              }}
                            >
                              {rowFiles[id].map((file, index) => {
                                const { file_name, url } = file;
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                      marginTop: "4px",
                                    }}
                                  >
                                    <a href={url} target="_blank">
                                      {/* {extractShortFilename(file)} */}
                                      {file_name}
                                    </a>
                                    <IconButton
                                      size="small"
                                      onClick={() => handleRemove(id, index)}
                                    >
                                      <Close fontSize="small" color="error" />
                                    </IconButton>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <SearchNotFound />
          )}
        </Scrollbar>
      </Card>
    </>
  );
}

export default CVTable;
