import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { extractShortFilename } from "../../utils/extractFilenameFromUrl";
import { Download } from "@mui/icons-material";
import { downloadFile } from "../../utils/downloadFile";

const ShowAssessments = ({ data }) => {
  //   console.log(data);
  const downloadFileFromURL = (fileUrl, fileName) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    link.style.display = "none"; // Hide the link
    document.body.appendChild(link);

    // Adding a small timeout to ensure the modal is fully rendered
    setTimeout(() => {
      link.click();
      document.body.removeChild(link); // Clean up the link
    }, 100);
  };
  return (
    <Grid container minWidth={"100px"} gap={1}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h5" component="div" mt={2}>
          CV:
        </Typography>

        <div
          style={{
            placeItems: "center",
            gap: 3,
            display: "flex",
            justifyContent: "space-between",
          }}
          key={data?.cv_url}
        >
          <a href={data?.cv_url} target="_blank" style={{ color: "#00AB55" }}>
            {data?.cv_filename}
          </a>
          {/* <IconButton onClick={() => downloadFileFromURL(data?.cv_url,data?.cv_filename)}><Download fontSize="small"/></IconButton> */}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h5" component="div" mt={2}>
          Client Assignments:
        </Typography>
        {data?.assignments?.map((item, index) => (
          <div
            style={{
              placeItems: "center",
              gap: 3,
              display: "flex",
              justifyContent: "space-between",
            }}
            key={index + 101}
          >
            <a href={item?.url} target="_blank" style={{ color: "#00AB55" }}>
              {extractShortFilename(item?.file_name)}
            </a>
            {/* <Download fontSize="small" /> */}
          </div>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h5" component="div" mt={2}>
          Uploaded Assignments:
        </Typography>
        {data?.submitted_assignments?.assignment_data?.map((item, index) => (
          <div
            style={{
              placeItems: "center",
              gap: 3,
              display: "flex",
              justifyContent: "space-between",
            }}
            key={index + item?.file_name}
          >
            <a href={item?.url} target="_blank" style={{ color: "#00AB55" }}>
              {item?.file_name}
            </a>
            {/* <Download fontSize="small" /> */}
          </div>
        ))}
      </Grid>
    </Grid>
  );
};

export default ShowAssessments;
